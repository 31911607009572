import React from "react";
import { Helmet } from "react-helmet";

function Impressum() {
  return (
    <article className="article-impressum">
      <Helmet>
        <title>Impressum · feine-weine.me</title>
        <meta name="description" content="Impressum von feine-weine.me" />
      </Helmet>
      <h1>Impressum</h1>

      <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
      <p>
        Angelika Maier
        <br />
        Dr. Martin Luther Str. 26
        <br />
        92237 Sulzbach-Rosenberg
      </p>

      <p>Steuernummer-ID: DE339755270</p>

      <h2>Kontakt</h2>
      <p>
        E-Mail: angelika@feine-weine.me
      </p>
    </article>
  );
}

export default Impressum;
