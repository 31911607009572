import React from 'react';
import Logo from './Images/logo.png';
import { BrowserRouter as Router, Switch, Route, NavLink } from 'react-router-dom';
import './App.css';
import Home from './Sites/Home';
import Impressum from './Sites/Impressum';

function App() {
  return (
    <Router>
      <main className="ifdigital-pwa-feineweine">

        <NavLink id="logo" exact to="/" activeClassName="active-link"><img src={Logo} alt="feine-weine.me" /></NavLink>
       
        <nav>
          <ul>
            <li>
              <NavLink exact to="/" activeClassName="active-link">Startseite</NavLink>
            </li>
            <li>
              <NavLink exact to="/impressum" activeClassName="active-link">Impressum</NavLink>
            </li>
          </ul>
        </nav>

        <Switch>
          <Route path="/impressum">
            <Impressum />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>

      </main>
    </Router>
  );
}

export default App;
