import React from 'react';
import { Helmet } from 'react-helmet';

function Home() {
  return (
    <article className="article-startseite">
      <Helmet>
        <title>Startseite · feine-weine.me</title>
        <meta name="description" content="Entdecken Sie feinste Weine aus Deutschland, Österreich und Südtirol auf www.feine-weine.de. Unser exklusives Sortiment bietet Ihnen eine unvergessliche Wein-Erfahrung. Jetzt bestellen und genießen!" />
      </Helmet>
      
      <h1>feine-weine.me</h1>
      <p>Willkommen auf unserer Website! Wir sind für Sie der Ansprechpartner wenn Sie auf der Suche nach feinen Weinen aus Deutschland, Österreich und Südtirol sind.</p>
      <h2>Kontakt</h2>
      <p>
        E-Mail: angelika@feine-weine.me
      </p>
    </article>
  );
}

export default Home;
